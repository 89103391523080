.basicChatContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 2%;
    margin-right: 2%;
}

.container {
    padding: 3.9%;
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;    
}

.prompt_store_button {
    background: black !important;
    color: white !important;
    opacity: 0.8;
    border: 0.1px solid lightgray;
    display: flex;
    align-items: center;
    padding-left: 3%;
    padding-right: 2%;
    border-radius: 16px !important;
    font-weight: bold;
    border-radius: 18px;
    min-width: 100% !important;
  }




@media only screen and (min-width: 800px) {
    .container_copy{
      font-size: 25px;
      margin: auto;
    }
    .container_header_banner{
        margin-bottom: 1% !important;
        margin-top: 0.5% !important;
        display: flex !important;
        align-items: center !important;
        
    }
    .container {
        padding: 1.5%;
        font-family: "Roboto", sans-serif;
        display: flex;
        flex-direction: column;    
    }
    iframe {
        width: 100%; /* Make the iframe expand to the container width */
        max-width: 800px; /* Set a maximum width to prevent it from becoming too wide */
        height: 400px; /* Adjust the height as needed */
    }

  }   

.container_header_banner{
    margin: 2%;
    margin-bottom: 3%;
    display: flex;
    align-items: center;
}

/*
style={{ flex: 1, display: "flex", flexDirection: "column"}}
*/
.container_body{
    display: flex;
    flex: 1;
    flex-direction: column;
}

/*
style={{paddingTop:"10%", paddingBottom:"10%"}}
*/
.container_copy{
    /* padding-top: 14%; */
    /* padding-top: 10%; */
    padding-top: 20%;
    padding-bottom: 8%;
}

/* Desktop styles */
@media (min-width: 870px) { /* Adjusted breakpoint for larger screens */
    .container_copy {
        padding-top: 3%;
        padding-bottom: 5%;
    }
}